import React, { useEffect, useState } from "react";
import axios from "axios";


import { useQuery } from "react-query";

import { urls } from "../../common";

import LoadingDots from "../../components/LoadingDots";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faBookCopy, faBoxArchive, faCheckSquare, faEye, faPaperPlane, faSearch, faSquare, faTrashCan, faTrashUndo } from "@fortawesome/pro-solid-svg-icons";
import Table from "../../components/Table";
import { CellContext, createColumnHelper } from "@tanstack/react-table";
import Tooltip from "../../components/Tooltip";
import { formatDate } from "../../common/dates";
import { toTitleCase } from "../../common/text";
import { PropsDeclaration } from "./types";
import { declarationStatusColour } from "./common";


const DeclarationsList = ({ MenuElement }: { MenuElement?: JSX.Element }) => {
    const [selectedRows, setSelectedRows] = useState<string[]>();
    const [archived, setArchived] = useState(false);
    const [search, setSearch] = useState<string>();

    const fetchURL = `${urls.remoteURL}declarations`;

    const { isLoading, isError, data, refetch } = useQuery(`company-declarations`, () => axios.get(fetchURL, { params: { archived } }));

    if (isError) {
        throw { code: 500, message: 'Error getting company declarations?' };
    }

    useEffect(() => { refetch(); }, [archived])

    type TableProps = {
        id: string,
        name: string,
        toName: string,
        toEmail: string,
        status: string,
        sent_date: number,
        signature_date: number,
        completed_date: number,
        created: number,
        updated: number;
    }


    const columnHelper = createColumnHelper<TableProps>();
    const columns = [
        columnHelper.accessor('name', {
            cell: info => <>
                {info.getValue()}
                {/* <span className="block md:hidden text-sm font-normal">{info.row.original.email}</span> */}
            </>,
            header: 'Name',
            footer: 'Name',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('toName', {
            cell: info => <>
                {info.getValue()}
                <span className="block text-sm font-normal">{info.row.original.toEmail}</span>
            </>,
            header: 'Sent To',
            footer: 'Sent To',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('sent_date', {
            cell: info => formatDate({ time: info.getValue() as number, formatStr: 'HH:mm do MMMM yyyy', empty: 'N/A' }),
            header: 'Sent',
            footer: 'Sent',
            sortingFn: 'alphanumeric',
        }),

        columnHelper.accessor('signature_date', {
            cell: info => formatDate({ time: info.getValue() as number, formatStr: 'HH:mm do MMMM yyyy', empty: 'N/A' }),
            header: 'Signed',
            footer: 'Signed',
            sortingFn: 'alphanumeric',
        }),

        // columnHelper.accessor('updated', {
        //     cell: info => formatDate({ time: info.getValue() as number, formatStr: 'HH:mm do MMMM yyyy', empty: 'N/A' }),
        //     header: 'Last Updated',
        //     footer: 'Last Updated',
        //     sortingFn: 'alphanumeric',
        // }),

        columnHelper.accessor('status', {
            cell: info => <span
                className={`${declarationStatusColour(info.getValue())} px-2 py-1 text-white rounded-full`}>{toTitleCase(info.getValue())}</span>,
            header: 'Status',
            footer: 'Status',
            sortingFn: 'alphanumeric',
        }),


        columnHelper.accessor('id', {
            header: 'Actions',
            footer: 'Actions',
            cell: info => actionCell(info),
            enableSorting: false,
            enableGlobalFilter: false,
            meta: {
                className: 'w-28 text-center'
            }
        }),
    ];

    const actionCell = (info: CellContext<TableProps, string>) => {
        return (
            <div className="grid md:flex gap-1 justify-center">

                <Tooltip content="View Declaration">
                    <Link to={`${info.getValue()}`} className="btn btn-green"><span className="sr-only">View</span><FontAwesomeIcon icon={faEye} fixedWidth /></Link>
                </Tooltip>

            </div >
        )
    }



    return (
        <>
            <div className="border-b bg-gray-50 border-gray-200 mb-4 sm:flex sm:items-center sm:justify-between" >
                {MenuElement}

                <div className="flex flex-wrap gap-2 items-center ml-auto mb-5">
                    {/* Search */}
                    <div className="flex flex-auto" >
                        <label className="form-control-addon-within rounded-full items-center">
                            <input
                                className="form-control border-none expanding"
                                placeholder="Search"
                                value={search}
                                onChange={(e) => { setSearch(e.target.value) }}
                            />
                            <span className="block text-gray-300 text-xl leading-none mr-4"><FontAwesomeIcon icon={faSearch} fixedWidth /></span>
                        </label>
                    </div>

                    <div className="flex gap-x-2">
                    </div>
                </div>
            </div>
            <div className="card p-5">
                <div className="overflow-x-auto">
                    {isLoading ? <LoadingDots /> :
                        <Table
                            defaultData={data?.data}
                            columns={columns}
                            options={{
                                search, selectable: false, selectedRows, selectableFunction: setSelectedRows,
                                selectDisabled: data?.data.filter((x: PropsDeclaration) => !!x.completed_date).map((x: PropsDeclaration) => x.id), selectDisabledIcon: faBan
                            }}
                        />
                    }
                </div>
            </div>
        </>
    )
}

export default DeclarationsList;